import React from "react"
import { Route, Switch } from "react-router-dom"
import Login from "../views/user/components/auth/Login"
import Register from "../views/user/components/auth/Register"
import Alert from "../views/shared/components/Alert"
import Dashboard from "../views/dashboard/components/Dashboard"
import CreateProfile from "../views/user/components/profile-forms/CreateProfile"
import EditProfile from "../views/user/components/profile-forms/EditProfile"
import Profiles from "../views/user/components/profiles/Profiles"
import Profile from "../views/user/components/profile/Profile"
import NotFound from "../views/shared/components/NotFound"
import { UserRoute, AdminRoute } from "./PrivateRoutes"
import EventsScreen from "../views/events/components/Events"
import EventScreen from "../views/events/components/Event"
import CreateEditEvent from "../views/events/components/CreateEditEvent"
import ManageUsers from "../views/user/components/admin/ManageUsers"
import AssignUsers from "../views/events/components/AssignUsers"
import UploadImages from "../views/events/components/UploadImages"
import IdentifiedFaces from "../views/faces/components/IdentifiedFaces"
import SearchFaces from "../views/faces/components/SearchFaces"
import Landing from "../views/shared/components/Landing"
import TagFaces from "../views/faces/components/TagFaces"
import TaggingScreen from "../views/faces/components/TaggingScreen"
import SearchBibs from "../views/faces/components/SearchBibs"
import SearchedFaceResults from "../views/faces/components/SearchedFaceResults"
import UserManagementScreen from "../views/user/components/cards/UserManagementScreen"
import TaggingManagement from "../views/user/components/admin/TaggingManagement"
import UserEventManagement from "../views/user/components/admin/UserEventManagement"
import ApplyBranding from "../views/events/components/ApplyBranding"
import Statistics from "../views/events/components/Statistics"
import PaymentScreen from "../views/payments/components/PaymentScreen"
import CreateEditParticipantPayment from "../views/payments/components/CreateEditParticipantPayment"
import ParticipantPayments from "../views/payments/components/ParticipantPayments"
import SearchLocationDate from "../views/faces/components/SearchLocationDate"
import GenericBrandingImages from "../views/events/components/GenericBrandingImages"
import BrandingScreen from "../views/events/components/BrandingScreen"
import ParticipantScreen from "../views/participants/components/ParticipantScreen"
import SeedParticipants from "../views/participants/components/SeedParticipants"
import CreateEditParticipants from "../views/participants/components/CreateEditParticipants"
import DynamicBranding from "../views/events/components/DynamicBranding"

import {
  APPLY_BRANDING_IMAGES,
  ASSIGN_PHOTOGRAPHERS,
  ASSIGN_USERS,
  ASSIGN_USER_EVENTS,
  BRANDING_HOME,
  CREATE_EDIT_PARTICIPANTS,
  CREATE_EVENT,
  CREATE_PROFILE,
  DASHBOARD_ROUTE,
  DYNAMIC_BRANDING_IMAGES,
  EDIT_PROFILE,
  ENCODE_IMAGES,
  EVENT,
  EVENTS,
  GENERIC_BRANDING_IMAGES,
  IDENTIFIED_IMAGES,
  LOGIN,
  LOGOUT,
  MANAGE_EVENTS,
  MANAGE_USERS,
  PARTICIPANT_HOME_PAGE,
  PAYMENTS,
  PAYMENTS_CONFIGURE_PARTICIPANTS,
  PAYMENTS_CREATE_PARTICIPANT_PAYMENT,
  PAYMENTS_VIEW_PARTICIPANT_PAYMENT,
  PROFILE,
  PROFILES,
  REGISTER,
  SALES_STATISTICS,
  SEARCH_FACES,
  SEARCH_IMAGES_LOCATION_DATE,
  SEARCH_TAGGED_FACES,
  SEED_PARTICIPANTS,
  STATISTICS,
  TAG_FACES,
  TAG_IMAGES,
  UPLOAD_IMAGES,
  USER_MANAGEMENT,
  VERIFY_CUSTOMER_SEARCHES,
  VIEW_IMAGES,
} from "./routers"
import ViewImages from "../views/events/components/ViewImages"
import SalesStatistics from "../views/events/components/SalesStatistics"

const Routes = () => {
  return (
    <section className="container">
      <Alert />
      <Switch>
        <Route exact path={LOGOUT} component={Landing} />
        <Route exact path={LOGIN} component={Login} />
        <Route exact path={REGISTER} component={Register} />
        <Route exact path={PROFILES} component={Profiles} />
        <Route exact path={PROFILE} component={Profile} />
        <Route exact path={DASHBOARD_ROUTE} component={Dashboard} />
        <Route exact path={CREATE_PROFILE} component={CreateProfile} />
        <Route exact path={EDIT_PROFILE} component={EditProfile} />
        <Route exact path={EVENTS} component={EventsScreen} />
        {/* <UserRoute exact path='/event' component={EventScreen} /> */}
        <AdminRoute exact path={EVENT} component={CreateEditEvent} />
        <Route exact path={MANAGE_EVENTS} component={EventScreen} />
        <AdminRoute exact path={ASSIGN_USERS} component={AssignUsers} />
        <AdminRoute exact path={ENCODE_IMAGES} component={AssignUsers} />
        <Route exact path={IDENTIFIED_IMAGES} component={IdentifiedFaces} />
        {/* <AdminRoute
          exact
          path='/event/:id/image-encodings'
          component={AssignUsers}
        /> */}
        <AdminRoute
          exact
          path={PARTICIPANT_HOME_PAGE}
          component={ParticipantScreen}
        />
        <AdminRoute
          exact
          path={SEED_PARTICIPANTS}
          component={SeedParticipants}
        />
        <AdminRoute
          exact
          path={CREATE_EDIT_PARTICIPANTS}
          component={CreateEditParticipants}
        />
        <AdminRoute exact path={BRANDING_HOME} component={BrandingScreen} />
        <AdminRoute
          exact
          path={GENERIC_BRANDING_IMAGES}
          component={GenericBrandingImages}
        />
        {/* <DndProvider backend={HTML5Backend}>
          
        </DndProvider> */}
        <AdminRoute
          exact
          path={DYNAMIC_BRANDING_IMAGES}
          component={DynamicBranding}
        />

        <AdminRoute
          exact
          path={APPLY_BRANDING_IMAGES}
          component={ApplyBranding}
        />
        <Route exact path={SEARCH_FACES} component={SearchFaces} />
        {/* <Route exact path={VERIFIED_IMAGES} component={VerifiedFaces} /> */}
        <Route exact path={TAG_FACES} component={TagFaces} />
        {/* <Route exact path={VERIFY_TAG_FACES} component={VerifyTagFaces} /> */}
        <Route exact path={SEARCH_TAGGED_FACES} component={SearchBibs} />
        <Route exact path={STATISTICS} component={Statistics} />
        <Route exact path={SALES_STATISTICS} component={SalesStatistics} />
        <Route
          exact
          path={VERIFY_CUSTOMER_SEARCHES}
          component={SearchedFaceResults}
        />
        <Route exact path={UPLOAD_IMAGES} component={UploadImages} />
        <Route exact path={VIEW_IMAGES} component={ViewImages} />
        <Route excat path={TAG_IMAGES} component={TaggingScreen} />
        {/* <Route exact path='/dashboard' component={Dashboard} /> */}
        <AdminRoute exact path={CREATE_EVENT} component={CreateEditEvent} />
        <AdminRoute exact path={MANAGE_USERS} component={ManageUsers} />
        <AdminRoute
          exact
          path={USER_MANAGEMENT}
          component={UserManagementScreen}
        />
        <AdminRoute
          exact
          path={ASSIGN_PHOTOGRAPHERS}
          component={TaggingManagement}
        />
        <AdminRoute
          exact
          path={ASSIGN_USER_EVENTS}
          component={UserEventManagement}
        />
        <AdminRoute
          exact
          path={PAYMENTS}
          component={PaymentScreen}
        ></AdminRoute>
        <AdminRoute
          exact
          path={PAYMENTS_CONFIGURE_PARTICIPANTS}
          component={ParticipantPayments}
        />
        <AdminRoute
          exact
          path={PAYMENTS_CREATE_PARTICIPANT_PAYMENT}
          component={CreateEditParticipantPayment}
        />
        <AdminRoute
          exact
          path={PAYMENTS_VIEW_PARTICIPANT_PAYMENT}
          component={CreateEditParticipantPayment}
        />
        <AdminRoute
          exact
          path={SEARCH_IMAGES_LOCATION_DATE}
          component={SearchLocationDate}
        />
        {/* <AdminRoute exact path='/manage-users' component={ManageUsers} /> */}
        {/* <PrivateRoute exact path='/add-experience' component={AddExperience} />
        <PrivateRoute exact path='/add-education' component={AddEducation} />
        <PrivateRoute exact path='/posts' component={Posts} />
        <PrivateRoute exact path='/posts/:id' component={Post} /> */}
        <Route component={NotFound} />
      </Switch>
    </section>
  )
}

export default Routes
