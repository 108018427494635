import axios from "axios"
import { CLEAR_IDENTIFIED_FACES, SET_LOADING } from "../../faces/actions/faceTypes"
import { setAlert } from "../../shared/actions/alert"

import {
  ADD_EVENT,
  UPDATE_EVENT,
  EVENT_ERROR,
  GET_EVENTS,
  GET_EVENT,
  GET_USER_EVENT_MAP,
  GET_UPLOADED_DATA,
  IDENTIFIER_EXECUTION_STARTED,
  IDENTIFIER_EXECUTION_FINISHED,
  BRANDING_STARTED,
  BRANDING_FINISHED,
  SET_PORTRAIT_BRANDING,
  SET_LANDSCAPE_BRANDING,
  SET_TAGGING,
  INCREMENT_UPLOAD_COUNT,
  SET_UPLOADED_LOCAL_FILES,
  UPDATE_UPLOADED_LOCAL_FILES,
  RESET_UPLOAD_COUNT,
  RESET_IGNORED_UPLOAD_COUNT,
  INCREMENT_IGNORED_UPLOAD_COUNT,
  SET_EXECUTION_ENVIRONMENT,
  SET_BIB_FILTER,
  SET_BRANDING_STATUS,
  SET_NON_BRANDED_IMAGES,
  SET_BRANDING_COUNT,
  SET_FAILED_BRANDING_COUNT,
  SET_EVENT_TYPES,
  SET_PHOTO_POINTS,
  SET_BRANDING_FILTERS,
  SET_BRANDING_FILTER_OPTIONS,
  SET_BRANDING_CATEGORY,
  SET_ALL_BRANDING_CATEGORIES,
  SET_BRANDING_RULES,
  SET_STATIC_PORTRAIT_BRANDING,
  SET_STATIC_LANDSCAPE_BRANDING,
  SET_BRANDING_RULE_CATEGORIES,
  BRANDING_IMAGES,
  SET_PHOTOGRAPHER_IMAGE_URLS,
  SET_EVENT_USERS,
  SET_SALES_STATISTICS,
} from "./eventTypes"

const config = {
  headers: { "Content-Type": "application/json" },
}

// Create or update profile
export const createEditEvent =
  (formData, bibData, history, id = null, bibId = null) =>
  async (dispatch) => {
    try {
      let filtered_bib = {}
      Object.keys(bibData).map((bib) => {
        if (bibData[bib] != null) {
          filtered_bib[bib] = bibData[bib]
        }
      })

      const body = JSON.stringify(formData)
      const bibBody = JSON.stringify(filtered_bib)

      if (id == null) {
        await axios.post("/api/events", body, config).then(async (res) => {
          dispatch({ type: ADD_EVENT, payload: res.data })
          const bibRes = await axios.post(
            `/api/events/${res.data.id}/bib_filter`,
            bibBody,
            config
          )
          dispatch({ type: SET_BIB_FILTER, payload: bibRes.data })
        })
      } else {
        const res = await axios.put(`/api/events/${id}/`, body, config)
        dispatch({ type: UPDATE_EVENT, payload: res.data })
        if (bibId == null) {
          const bibRes = await axios.post(
            `/api/events/${id}/bib_filter`,
            bibBody,
            config
          )
          dispatch({ type: SET_BIB_FILTER, payload: bibRes.data })
        } else {
          const bibRes = await axios.put(
            `/api/events/${id}/bib_filter/${bibId}`,
            bibBody,
            config
          )
          dispatch({ type: SET_BIB_FILTER, payload: bibRes.data })
        }
      }
      dispatch(
        setAlert(id != null ? "Event Updated" : "Event Created", "success")
      )
      history.push("/events")
    } catch (err) {
      const errors = err.response.data

      if (errors["name"]) {
        errors["name"].forEach((msg) =>
          dispatch(setAlert(`Name: ${msg}`, "danger"))
        )
      }

      if (errors["location"]) {
        errors["location"].forEach((msg) =>
          dispatch(setAlert(`Location: ${msg}`, "danger"))
        )
      }

      if (errors["type"]) {
        errors["type"].forEach((msg) =>
          dispatch(setAlert(`Type: ${msg}`, "danger"))
        )
      }

      if (errors["is_active"]) {
        errors["is_active"].forEach((msg) =>
          dispatch(setAlert(`IsActive: ${msg}`, "danger"))
        )
      }

      if (errors["from_date"]) {
        errors["from_date"].forEach((msg) =>
          dispatch(setAlert(`FromDate: ${msg}`, "danger"))
        )
      }

      if (errors["to_date"]) {
        errors["to_date"].forEach((msg) =>
          dispatch(setAlert(`ToDate: ${msg}`, "danger"))
        )
      }

      if (errors["organizer"]) {
        errors["organizer"].forEach((msg) =>
          dispatch(setAlert(`Organizer: ${msg}`, "danger"))
        )
      }

      if (errors["sponsors"]) {
        errors["sponsors"].forEach((msg) =>
          dispatch(setAlert(`Sponsors: ${msg}`, "danger"))
        )
      }
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

// Set event to event Reference
// export const setEventToEventReference =
//   (eventId, associatedEvents) => async (dispatch) => {
//     try {
//       const body = JSON.stringify({
//         event_id: eventId,
//         events: associatedEvents,
//       })
//       await axios
//         .post('/api/events/event/reference', body, config)
//         .then((response) => {
//           console.log(' OUT ')
//         })
//     } catch (err) {
//       dispatch({
//         type: EVENT_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status },
//       })
//     }
//   }

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENTS, payload: [] })
    await axios.delete(`/api/events/${eventId}/`, config).then((response) => {
      dispatch(getEvents())
    })
  } catch (err) {
    dispatch({ type: EVENT_ERROR })
  }
}

// Get All Events
export const getEvents = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/events")
    dispatch({ type: GET_EVENTS, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Get All Event Types
export const getEventTypes = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/events/types")
    dispatch({ type: SET_EVENT_TYPES, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Get All Event Photo Points
export const getEventPhotoPoints = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/events/${id}/photo-points`)
    dispatch({ type: SET_PHOTO_POINTS, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Clear All Event Photo Points
export const clearEventPhotoPoints = () => async (dispatch) => {
  try {
    dispatch({ type: SET_PHOTO_POINTS, payload: null })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Set BIB filter by Id
export const setBIBFilterByEventId = (id) => async (dispatch) => {
  try {
    let response = await axios.get(`/api/events/${id}/bib_filter`)
    if (response.data[0] === undefined) {
      response = {
        bib_start_range: "",
        bib_end_range: "",
        bib_length: "",
        bib_prefix: "",
        bib_suffix: "",
        is_bib_present: false,
      }
    } else {
      response = response.data[0]
    }
    dispatch({ type: SET_BIB_FILTER, payload: response })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Get All Profiles
export const getEventsByUser = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/events/${userId}`)
    dispatch({ type: GET_EVENTS, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Get All Profiles
export const getEventById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT, payload: id })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

//Get User-Event Mapping
export const getUserEventMappings = (eventId) => async (dispatch) => {
  try {
    const res = await axios.get("/api/user/event/mapping/" + eventId)
    dispatch({ type: GET_USER_EVENT_MAP, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getEventUsers = (eventId, role) => async (dispatch) => {
  try {
    const res = await axios
      .get(
        `/api/user/event/${eventId}/users`,
        { params: { role: role } },
        config
      )
      .then((response) => {
        dispatch({
          type: SET_EVENT_USERS,
          payload: response.data,
        })
      })
    //dispatch({ type: SET_EVENT_USERS, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getImageUploadUserEventMappings =
  (eventId, userId) => async (dispatch) => {
    try {
      const res = await axios.get("/api/user/event/mapping", {
        params: {
          event_id: eventId,
          user_id: userId,
        },
        config,
      })
      dispatch({ type: GET_USER_EVENT_MAP, payload: res.data })
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

export const clearUserEventMappings = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_EVENT_MAP, payload: null })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

//Get Local Uploaded Images
export const getUploadedLocalImages = (eventId, userId) => async (dispatch) => {
  try {
    //Updates the upload info in db
    await axios
      .get(
        "/api/images/local/uploads",
        {
          params: {
            event_id: eventId,
            user_id: userId,
          },
        },
        config
      )
      .then((response) =>
        dispatch({
          type: SET_UPLOADED_LOCAL_FILES,
          payload:
            response.data.local_images !== null
              ? response.data.local_images
              : [],
        })
      )
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const clearUploadedLocalImages = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPLOADED_LOCAL_FILES,
      payload: null,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getExecutionEnvironment = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/environment")
    dispatch({ type: SET_EXECUTION_ENVIRONMENT, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const updateUploadedLocalImageStorage =
  (files, file) => async (dispatch) => {
    try {
      //Updates the upload info in db
      files.push(file)
      dispatch({ type: UPDATE_UPLOADED_LOCAL_FILES, payload: files })
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

//Get User-Event Mapping
export const getUserPhotographerTaggings = (eventId) => async (dispatch) => {
  try {
    const res = await axios.get("/api/user/event/tagging/" + eventId)
    dispatch({ type: SET_TAGGING, payload: res.data })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

//Get User-Event Mapping
export const setUserEventMappings =
  (eventId, updatedList, existingList) => async (dispatch) => {
    try {
      if (updatedList.length > 0) {
        updatedList.map(async (updatedData) => {
          try {
            const index = existingList.findIndex(
              (obj) => obj.user.id === updatedData.id
            )
            const body = JSON.stringify({
              event_id: eventId,
              user_id: updatedData.id,
            })
            if (index == -1) {
              //POST
              const res = await axios.post(
                "/api/user/event/mapping",
                body,
                config
              )
            } else {
              if (updatedData.is_assigned == false) {
                //DELETE
                const res = await axios.delete(
                  "/api/user/event/mapping/" + eventId + "/" + updatedData.id,
                  config
                )
              }
              // Else ignore as there is no change
            }

            //const body = JSON.stringify(userData)
            //const res = await axios.put('/api/users', body, config)
            //dispatch({ type: UPDATE_USERS, payload: res.data })
          } catch (err) {
            console.log(err)
          }
        })
      }

      //const res = await axios.get('/api/user/event/mapping/' + eventId)
      //dispatch({ type: GET_USER_EVENT_MAP, payload: res.data })
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

//Get User-Event Mapping
export const clearAssignUserEvents = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_TAGGING,
      payload: null,
    })
    dispatch({
      type: GET_USER_EVENT_MAP,
      payload: [],
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

//Get User-Event Mapping
export const setUserEventTagMappings =
  (eventId, photographerId, userId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        assigned_photographer_id: photographerId,
        event_id: eventId,
        user_id: userId,
      })

      const res = await axios.post("/api/user/event/tagging", body, config)
      //   .then((response) => {
      //     //getUserPhotographerTaggings(eventId)
      //   }
      // )
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

// Post User Event Mapping
export const postUserEventMapping = (userData, e_id) => async (dispatch) => {
  try {
    if (userData.length > 0) {
      userData.map(async (userData) => {
        try {
          const body = JSON.stringify({
            event_id: e_id,
            user_id: userData.id,
          })
          const res = await axios.post("/api/user/event/mapping", body, config)
          //dispatch({ type: UPDATE_USERS, payload: res.data })
        } catch (err) {
          console.log(err)
        }
      })
    }
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const incrementUploadCount = () => async (dispatch) => {
  try {
    dispatch({
      type: INCREMENT_UPLOAD_COUNT,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const incrementIgnoredUploadCount = () => async (dispatch) => {
  try {
    dispatch({
      type: INCREMENT_IGNORED_UPLOAD_COUNT,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const resetUploadCount = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_UPLOAD_COUNT,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const resetIgnoredUploadCount = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_IGNORED_UPLOAD_COUNT,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}
// Post User Event Mapping
export const insertImage = (uploadData) => {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      const config = {
        headers: { "Content-Type": "application/json" },
      }

      try {
        //Updates the upload info in db
        const body = JSON.stringify(uploadData)
        const res = await axios
          .post("/api/images", body, config)
          .then((response) => {
            return resolve(response)
          })
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        dispatch(getUploadedData(uploadData.event, uploadData.user))
        return resolve(err.response.status)
      }
    })
  }
}

export const getPhotographerImageUrls =
  (eventId, imageType, photographerId) => async (dispatch) => {
    try {
      let parameters = {
        event_id: eventId,
        type: imageType,
      }
      if (photographerId === 0) {
        parameters.photographer_id = null
      } else {
        parameters.photographer_id = photographerId
      }
      await axios
        .get(
          "/api/images/photographer-cloud-urls",
          {
            params: parameters,
          },
          config
        )
        .then((response) =>
          dispatch({
            type: SET_PHOTOGRAPHER_IMAGE_URLS,
            payload: response.data,
          })
        )
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

// Get
export const getNonBrandedImages = (eventId) => async (dispatch) => {
  try {
    await axios
      .get(
        "/api/images/apply/branding",
        {
          params: {
            event_id: eventId,
          },
        },
        config
      )
      .then((response) =>
        dispatch({
          type: SET_NON_BRANDED_IMAGES,
          payload: response.data.images,
        })
      )
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const clearNonBrandingImages = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_NON_BRANDED_IMAGES,
      payload: null,
    })
    dispatch({
      type: SET_BRANDING_COUNT,
      payload: 0,
    })
    dispatch({
      type: SET_FAILED_BRANDING_COUNT,
      payload: 0,
    })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const applyBrandingOnImage = (imageKeys) => {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        if (imageKeys === null) {
          dispatch(setAlert("Error Applying Brand", "danger"))
          return
        }
        const body = JSON.stringify({
          original_path: imageKeys[0],
          stripped_path: imageKeys[1],
          tnail_path: imageKeys[2],
        })
        await axios
          .post("/api/images/apply/branding", body, config)
          .then((response) => {
            if (response.status === 200) {
              dispatch({ type: SET_BRANDING_COUNT, payload: 1 })
            } else {
              dispatch({ type: SET_FAILED_BRANDING_COUNT, payload: 1 })
            }
            return resolve(true)
          })
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        return resolve(true)
      }
    })
  }
}

// Get
export const initiateCloudImageBranding = (eventId) => async (dispatch) => {
  try {
    const body = JSON.stringify({
      event_id: eventId,
    })
    await axios
      .post("/api/images/apply/branding", body, config)
      .then((response) =>
        dispatch({ type: SET_BRANDING_STATUS, payload: response.data })
      )
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

// Get
export const getUploadedData = (eventId, userId) => async (dispatch) => {
  try {
    //Updates the upload info in db
    await axios
      .get(
        "/api/images/local/uploads/stats",
        {
          params: {
            event_id: eventId,
            user_id: userId,
          },
        },
        config
      )
      .then((response) =>
        dispatch({ type: GET_UPLOADED_DATA, payload: response.data })
      )
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const startIdentifierModel = (eventId) => async (dispatch) => {
  try {
    const body = JSON.stringify({
      event_id: eventId,
    })
    dispatch({ type: IDENTIFIER_EXECUTION_STARTED })
    await axios
      .post("/api/images/fr/identify-face-matches", body, config)
      .then((response) => {
        dispatch(setAlert("Identifier Model Execution Completed", "success"))
        dispatch({ type: CLEAR_IDENTIFIED_FACES, payload: null })
        dispatch({ type: IDENTIFIER_EXECUTION_FINISHED })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const sendBrandingImage = (
  file,
  eventId,
  event,
  brandingType,
  ruleName
) => {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        let form_data = new FormData()
        form_data.append("file", file)
        form_data.append("type", brandingType)
        form_data.append("event", event)
        form_data.append("ruleName", ruleName)
        dispatch({ type: BRANDING_STARTED })
        await axios
          .post(
            "/api/events/" + eventId + "/branding/upload",
            form_data,
            config
          )
          .then(async (response) => {
            let body
            if (brandingType == "portrait") {
              body = JSON.stringify({
                portrait_image: response["data"]["brandingKey"],
                rule_name: ruleName,
              })
            } else {
              body = JSON.stringify({
                landscape_image: response["data"]["brandingKey"],
                rule_name: ruleName,
              })
            }
            await axios
              .post("/api/events/" + eventId + "/branding", body, config)
              .then((response) => {
                dispatch(
                  setAlert(
                    brandingType.toUpperCase() + " branding image uploaded",
                    "success"
                  )
                )
                dispatch({ type: BRANDING_FINISHED })
                resolve(response.data)
              })
          })
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        resolve(0)
      }
    })
  }
}

export const getBrandingImages = (eventId) => async (dispatch) => {
  try {
    await axios
      .get("/api/events/" + eventId + "/branding", {}, config)
      .then((response) => {
        dispatch({
          type: BRANDING_IMAGES,
          payload: response.data,
        })
        dispatch({ type: BRANDING_FINISHED })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

//Dynamic Branding
export const getBrandingFilters = () => async (dispatch) => {
  try {
    await axios
      .get("/api/participants/filters", {}, config)
      .then((response) => {
        dispatch({
          type: SET_BRANDING_FILTERS,
          payload: response.data,
        })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getBrandingFilterOptions = (fields) => async (dispatch) => {
  try {
    await axios
      .get(
        "/api/participants/filter/options",
        {
          params: {
            fields: JSON.stringify(fields),
          },
        },
        config
      )
      .then((response) => {
        dispatch({ type: SET_BRANDING_FILTER_OPTIONS, payload: response.data })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getBrandingCategories = (event_id) => async (dispatch) => {
  try {
    await axios
      .get(`/api/events/${event_id}/branding-categories`, {}, config)
      .then((response) => {
        dispatch({
          type: SET_BRANDING_CATEGORY,
          payload: response.data,
        })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getAllBrandingCategories = (event_id) => async (dispatch) => {
  try {
    await axios
      .get(
        `/api/events/${event_id}/branding-categories`,
        { params: { all_records: true } },
        config
      )
      .then((response) => {
        dispatch({
          type: SET_ALL_BRANDING_CATEGORIES,
          payload: response.data,
        })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const createBrandingCategory =
  (eventId, filter, values) => async (dispatch) => {
    try {
      values.map(async (value) => {
        const body = JSON.stringify({
          filter: filter,
          value: value,
        })
        await axios
          .post(`/api/events/${eventId}/branding-categories`, body, config)
          .then((response) => {
            dispatch(getBrandingCategories(eventId))
          })
      })
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      })
    }
  }

export const putBrandingCategories =
  (eventId, filter, value) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        filter: filter,
        value: value,
      })
      await axios
        .put(`/api/events/${eventId}/branding-categories`, body, config)
        .then((response) => {
          dispatch({
            type: SET_BRANDING_FILTERS,
            payload: response.data,
          })
        })
    } catch (err) {
      dispatch({ type: EVENT_ERROR })
    }
  }

export const deleteBrandingCategories =
  (eventId, filter) => async (dispatch) => {
    try {
      await axios
        .delete(
          `/api/events/${eventId}/branding-categories`,
          {
            params: {
              filter: filter,
            },
          },
          config
        )
        .then((response) => {
          dispatch({
            type: SET_BRANDING_FILTERS,
            payload: response.data,
          })
        })
    } catch (err) {
      dispatch({ type: EVENT_ERROR })
    }
  }

export const createBrandingRuleComponents =
  (ruleName, categories, landscapeFile, portraitFile, event) =>
  async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch(
          sendBrandingImage(
            landscapeFile[0],
            event.id,
            event.name,
            "landscape",
            ruleName
          )
        ).then((landscape) => {
          dispatch(
            sendBrandingImage(
              portraitFile[0],
              event.id,
              event.name,
              "portrait",
              ruleName
            )
          ).then((portrait) => {
            resolve(
              dispatch(
                postBrandingRule(event.id, ruleName, portrait.id, categories)
              )
            )
          })
        })
      } catch (err) {
        resolve(false)
        dispatch({
          type: EVENT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
      }
    })
  }

export const getBrandingRules = (event_id) => async (dispatch) => {
  try {
    await axios
      .get(`/api/events/${event_id}/branding-rule`, {}, config)
      .then((response) => {
        dispatch({
          type: SET_BRANDING_RULES,
          payload: response.data,
        })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const postBrandingRule =
  (eventId, ruleName, brandingImageId, categories) => async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const body = JSON.stringify({
          event_id: eventId,
          rule_name: ruleName,
          branding_images_id: brandingImageId,
        })
        await axios
          .post(`/api/events/${eventId}/branding-rule`, body, config)
          .then(async (response) => {
            var promises = categories.map((category) => {
              return new Promise(async (resolve) => {
                const body = JSON.stringify({
                  category: category.label,
                  rule_name: ruleName,
                })
                await axios
                  .post(
                    `/api/events/${eventId}/branding-rule-categories`,
                    body,
                    config
                  )
                  .then((response) => {
                    resolve(true)
                  })
              })
            })
            Promise.all(promises).then(function () {
              resolve(true)
            })
          })
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        resolve(false)
      }
    })
  }

// export const putBrandingRule = (eventId, filter, value) => async (dispatch) => {
//   try {
//     const body = JSON.stringify({
//       filter: filter,
//       value: value,
//     })
//     await axios
//       .put(`/api/events/${eventId}/branding-rule`, body, config)
//       .then((response) => {
//         dispatch({
//           type: SET_BRANDING_FILTERS,
//           payload: response.data,
//         })
//       })
//   } catch (err) {
//     dispatch({ type: EVENT_ERROR })
//   }
// }

export const deleteBrandingRule = (eventId, ruleName) => async (dispatch) => {
  try {
    await axios
      .delete(
        `/api/events/${eventId}/branding-rule`,
        {
          params: {
            rule_name: ruleName,
          },
        },
        config
      )
      .then(async (response) => {
        await axios.delete(
          "/api/events/" + eventId + "/branding",
          {
            params: {
              rule_name: ruleName,
            },
          },
          config
        )
      })
  } catch (err) {
    dispatch({ type: EVENT_ERROR })
  }
}

export const getBrandingRuleCategories = (event_id) => async (dispatch) => {
  try {
    await axios
      .get(`/api/events/${event_id}/branding-rule-categories`, {}, config)
      .then((response) => {
        dispatch({
          type: SET_BRANDING_RULE_CATEGORIES,
          payload: response.data,
        })
      })
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const postBrandingRuleCategories =
  (eventId, filter, value) => async (dispatch) => {
    try {
      try {
        const body = JSON.stringify({
          filter: filter,
          value: value,
        })
        await axios
          .post(`/api/events/${eventId}/branding-rule-categories`, body, config)
          .then((response) => {
            // dispatch({
            //   type: SET_BRANDING_FILTERS,
            //   payload: response.data,
            // })
          })
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      })
    }
  }

export const putBrandingRuleCategories =
  (eventId, filter, value) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        filter: filter,
        value: value,
      })
      await axios
        .put(`/api/events/${eventId}/branding-rule-categories`, body, config)
        .then((response) => {
          dispatch({
            type: SET_BRANDING_FILTERS,
            payload: response.data,
          })
        })
    } catch (err) {
      dispatch({ type: EVENT_ERROR })
    }
  }

export const deleteBrandingRuleCategories =
  (eventId, categoryVal, ruleName) => async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        await axios
          .delete(
            `/api/events/${eventId}/branding-rule-categories`,
            {
              params: {
                category: categoryVal,
                rule_name: ruleName,
              },
            },
            config
          )
          .then((response) => {
            resolve(true)
          })
      } catch (err) {
        dispatch({ type: EVENT_ERROR })
        resolve(false)
      }
    })
  }


  // Get All Events
  export const getSalesStatistics = (eventId) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true })
      await axios
        .get(
          "/api/images/sales/stats",
          {
            params: {
              event_id: eventId
            },
          },
          config
        )
        .then((response) => {
          dispatch({ type: SET_SALES_STATISTICS, payload: response.data })
        })
    } catch (err) {
      dispatch({ type: SET_LOADING, payload: false })
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }