import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from "semantic-ui-react";
import "./SalesPagination.css";

const SalesPagination = ({ salesStats }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'asc' });

  const totalRecords = salesStats.payments.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key: column, direction: direction });
  };

  const sortedItems = [...salesStats.payments].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });

  const currentSortedItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRecords / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    setCurrentPage(1); // Reset to first page on sort change
  }, [sortConfig]);

  return (
    <div>
      {totalRecords > 0 ? (
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell
                colSpan="6"
                style={{ textAlign: "center" }}
              >
                Payments
              </TableHeaderCell>
            </TableRow>
            <TableRow>
              <TableHeaderCell onClick={() => handleSort('contact')}>
                Contact
                {sortConfig.key === 'contact' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
              <TableHeaderCell onClick={() => handleSort('status')}>
                Status
                {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
              <TableHeaderCell onClick={() => handleSort('amount')}>
                Amount
                {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
              <TableHeaderCell onClick={() => handleSort('gateway_charge')}>
                Gateway Charge
                {sortConfig.key === 'gateway_charge' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
              <TableHeaderCell onClick={() => handleSort('tax')}>
                Tax
                {sortConfig.key === 'tax' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
              <TableHeaderCell onClick={() => handleSort('created_at')}>
                Date
                {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentSortedItems.map((payment, index) => (
              <TableRow key={index}>
                <TableCell>{payment.contact}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{payment.gateway_charge}</TableCell>
                <TableCell>{payment.tax}</TableCell>
                <TableCell>{payment.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan="6">No payments found</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={currentPage === pageNumber ? 'active' : ''}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SalesPagination;