import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const SalesStatisticsCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Sales Statistics</Card.Header>
        <Card.Meta>Image Sale Statistics</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>
          Image Sale Statistics
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link to={`/event/${event.id}/sales/stats`} className='btn btn-light'>
              View Statistics
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default SalesStatisticsCard
