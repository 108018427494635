import React from "react"
import { Card } from "semantic-ui-react"
import { useSelector } from "react-redux"
import UploadImagesCard from "./UploadImagesCard"
import TagImages from "./TagImages"
import StatisticsCard from "./StatisticsCard"
import SalesStatisticsCard from "./SalesStatisticsCard"
import ImageFaceSearchCard from "./ImageFaceSearchCard"
import ImageLocationSearchCard from "./ImageLocationSearchCard"
import BrandingMasterCard from "./BrandingMasterCard"
import ParticipantCards from "../../../participants/components/cards/ParticipantCards"
import SearchTaggedImagesCard from "../../../faces/components/cards/SearchTaggedImagesCard"
import ViewImagesCard from "./ViewImagesCard"
import VerifyCustomerSearchCard from "./VerifyCustomerSearchCard"

const EventCard = (event) => {
  const auth = useSelector((state) => state.auth)
  const { isAuthenticated, loading, role } = auth

  const adminCards = (
    <Card.Group>
      {/* <AssignUsersCard {...event} /> */}
      <ParticipantCards {...event} />
      <BrandingMasterCard {...event} />
      <UploadImagesCard {...event} />
      <ViewImagesCard {...event} />
      <TagImages {...event} />
      <ImageFaceSearchCard {...event} />
      <SearchTaggedImagesCard {...event} />
      <ImageLocationSearchCard {...event} />
      <VerifyCustomerSearchCard {...event} />
      {/* <IdentifierCard {...event} />
      <IdentifiedImagesCard {...event} />  */}
      {/* <ImageEncodingsCard {...event} /> */}
      {/* <EncodeImagesCard {...event} /> */}
      {/* <VerifiedImagesCard {...event} /> */}
      <StatisticsCard {...event} />
      <SalesStatisticsCard {...event} />
    </Card.Group>
  )

  const userCards = (
    <Card.Group>
      <UploadImagesCard {...event} />
      <StatisticsCard {...event} />
    </Card.Group>
  )

  const contractorCards = (
    <Card.Group>
      <TagImages {...event} />
      <StatisticsCard {...event} />
    </Card.Group>
  )

  const coordinatorCards = (
    <Card.Group>
      <UploadImagesCard {...event} />
      <ViewImagesCard {...event} />
      <TagImages {...event} />
      <ImageFaceSearchCard {...event} />
      <SearchTaggedImagesCard {...event} />
      <ImageLocationSearchCard {...event} />
      <SalesStatisticsCard {...event} />
      {/* <IdentifiedImagesCard {...event} />
      <VerifiedImagesCard {...event} /> */}
      {/* <StatisticsCard {...event} /> */}
    </Card.Group>
  )

  return (
    <div>
      {!loading &&
        isAuthenticated &&
        (role == "ADMIN"
          ? adminCards
          : role == "EVENT_COORDINATOR"
          ? coordinatorCards
          : role == "CONTRACTOR"
          ? contractorCards
          : userCards)}
    </div>
  )
}

export default EventCard
