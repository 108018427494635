import React, { Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react"
import Spinner from "../../shared/components/Spinner"
import SalesPagination from "./SalesPagination"
import { getSalesStatistics } from "../actions/eventActions"
import { SET_SALES_STATISTICS } from "../actions/eventTypes"

const SalesStatistics = () => {
  const { event, salesStats, loading } = useSelector((state) => state.eventReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (event) {
        dispatch({ type: SET_SALES_STATISTICS, payload: null });
        dispatch(getSalesStatistics(event.id))
    }
  }, [dispatch, event])

  return (
    <Fragment>
      <h1 className="large text-primary">Sales Statistics - {event.name}</h1>
      {loading  || salesStats == null ? (
        <Spinner />
      ) : (
        <>
          {/* <h2 className="medium text-primary">Sales Summary</h2> */}
          
          <Table celled>
            <TableHeader>
              <TableRow>
                <TableHeaderCell
                  colSpan="3"
                  style={{ textAlign: "center" }}
                >
                  Payment Summary
                </TableHeaderCell>
                <TableHeaderCell
                  colSpan="3"
                  style={{ textAlign: "center" }}
                >
                  Transaction Summary
                </TableHeaderCell>
              </TableRow>
              <TableRow>
                <TableHeaderCell>Amount</TableHeaderCell>
                <TableHeaderCell>GST</TableHeaderCell>
                <TableHeaderCell>Gateway Charges</TableHeaderCell>
                <TableHeaderCell>Total </TableHeaderCell>
                <TableHeaderCell>Failed </TableHeaderCell>
                <TableHeaderCell>Refunded </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>{salesStats?.amount}</TableCell>
                <TableCell>{salesStats?.tax}</TableCell>
                <TableCell>{salesStats?.gateway_charge}</TableCell>
                <TableCell>{salesStats?.total}</TableCell>
                <TableCell>{salesStats?.failed}</TableCell>
                <TableCell>{salesStats?.refunded}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <SalesPagination salesStats={salesStats} />

          {/* <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell
                  colSpan="7" style={{ textAlign: "center" }}
                >
                  Payments
                </TableHeaderCell>
              </TableRow>
              <TableRow>
                <TableHeaderCell>Contact</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Payment Status</TableHeaderCell>
                <TableHeaderCell>Amount</TableHeaderCell>
                <TableHeaderCell>Gateway Charges</TableHeaderCell>
                <TableHeaderCell>GST</TableHeaderCell>
              </TableRow>
            </TableHeader>
            {salesStats.payments.length > 0 ? (
               <SalesPagination {...salesStats}/>
            ) : (
              <TableRow>
                <TableCell colSpan="6">No payments found</TableCell>
              </TableRow>
            )}
          </Table> */}
              </>
            )}
    </Fragment>
  )
}

export default SalesStatistics
