export const ADD_EVENT = "CREATE_EVENT"
export const UPDATE_EVENT = "UPDATE_EVENT"
export const EVENT_ERROR = "EVENT_ERROR"
export const GET_EVENTS = "GET_EVENTS"
export const SET_EVENT = "SET_EVENT"
export const GET_EVENT = "GET_EVENT"
export const SET_EVENT_TYPES = "SET_EVENT_TYPES"
export const SET_EVENT_USERS = "SET_EVENT_USERS"
export const SET_PHOTO_POINTS = "SET_PHOTO_POINTS"
export const GET_USER_EVENT_MAP = "GET_USER_EVENT_MAP"
export const GET_UPLOADED_DATA = "GET_UPLOADED_DATA"
export const GET_TAGGING = "GET_TAGGING"
export const SET_TAGGING = "SET_TAGGING"
export const SET_UPLOADED_LOCAL_FILES = "SET_UPLOADED_LOCAL_FILES"
export const UPDATE_UPLOADED_LOCAL_FILES = "UPDATE_UPLOADED_LOCAL_FILES"
export const IDENTIFIER_EXECUTION_STARTED = "IDENTIFIER_EXECUTION_STARTED"
export const IDENTIFIER_EXECUTION_FINISHED = "IDENTIFIER_EXECUTION_FINISHED"
export const BRANDING_STARTED = "BRANDING_STARTED"
export const BRANDING_FINISHED = "BRANDING_FINISHED"
export const BRANDING_IMAGES = "BRANDING_IMAGES"

export const SET_PORTRAIT_BRANDING = "SET_PORTRAIT_BRANDING"
export const SET_LANDSCAPE_BRANDING = "SET_LANDSCAPE_BRANDING"

export const SET_STATIC_PORTRAIT_BRANDING = "SET_STATIC_PORTRAIT_BRANDING"
export const SET_STATIC_LANDSCAPE_BRANDING = "SET_STATIC_LANDSCAPE_BRANDING"
export const INCREMENT_UPLOAD_COUNT = "INCREMENT_UPLOAD_COUNT"
export const RESET_UPLOAD_COUNT = "RESET_UPLOAD_COUNT"
export const INCREMENT_IGNORED_UPLOAD_COUNT = "INCREMENT_IGNORED_UPLOAD_COUNT"
export const RESET_IGNORED_UPLOAD_COUNT = "RESET_IGNORED_UPLOAD_COUNT"
export const SET_EXECUTION_ENVIRONMENT = "EXECUTION_ENVIRONMENT"
export const SET_BIB_FILTER = "SET_BIB_FILTER"
export const SET_BRANDING_COUNT = "SET_BRANDING_COUNT"
export const SET_FAILED_BRANDING_COUNT = "SET_FAILED_BRANDING_COUNT"
export const SET_NON_BRANDED_IMAGES = "SET_NON_BRANDED_IMAGES"
export const SET_PHOTOGRAPHER_IMAGE_URLS = "SET_PHOTOGRAPHER_IMAGE_URLS"
export const SET_BRANDING_STATUS = "SET_BRANDING_STATUS"
export const SET_BRANDING_FILTERS = "SET_BRANDING_FILTERS"
export const SET_BRANDING_FILTER_OPTIONS = "SET_BRANDING_FILTER_OPTIONS"
export const SET_BRANDING_CATEGORY = "SET_BRANDING_CATEGORY"
export const SET_ALL_BRANDING_CATEGORIES = "SET_ALL_BRANDING_CATEGORIES"
export const SET_RULES_NUMBERS = "SET_RULES_NUMBERS"
export const SET_BRANDING_RULES = "SET_BRANDING_RULES"
export const SET_BRANDING_RULE_CATEGORIES = "SET_BRANDING_RULE_CATEGORIES"
export const SET_SALES_STATISTICS = "SET_SALES_STATISTICS"
